body {
	background-image: url('https://images.unsplash.com/photo-1516211881327-e5120a941edc?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
	margin: 0 auto;
	margin-bottom: 5rem;
	display: table;
	height: 100vh;
	background-position: center 0px;
	background-repeat: no-repeat;
	background-size: cover;
}

.pageContainer {
	display: table-cell;
	vertical-align: middle;
	width: 1024px;
	height: auto;

	min-width: 1024px;
	overflow: hidden;
}
.leftContainer {
	float: left;
	height: 100%;
	width: 30%;
	display: table-cell;
	text-align: center;
	background-color: #202020;
	color: #dcdcdc;
}
.rightContainer {
	float: left;
	height: 100%;
	width: 67.5%;
	position: relative;
}
.restName {
	margin-top: 20%;
	height: 10%;
}
.restDescription {
	width: 70%;
	margin: auto;
}
.navbar {
	margin-top: 17%;

	display: inline-block;
	position: relative;
	width: 100%;
}

.navbar a {
	display: inline-block;
	background-color: #202020;
	color: white;
	text-align: center;
	padding: 14px 16px;
	padding-left: 5%;
	padding-right: 5%;
	text-decoration: none;
	font-size: 17px;
}

.navbar a:hover {
	color: #b22222;
}

.pageFooter {
	position: relative;
	bottom: -25%;
	width: initial;
	margin: 20px;
	text-align: center;
}
.menuContainer {
	display: block;
	margin-left: 5%;
	margin-top: 5%;
	width: 94%;
	height: 100%;
	background-color: #202020;
	color: #dcdcdc;
	text-align: center;
}
#itemHome {
	display: block;
	width: 100%;
	height: 100%;
	margin-bottom: 2rem;
	color: white;
}
#itemMenu {
	width: 100%;
	height: 100%;

	color: white;
}
#itemContact {
	width: 100%;
	height: 100%;

	color: white;
}
table {
	width: 100%;
	color: #dcdcdc;
}
.LeftHolder {
	text-align: left;
}
.RightHolder {
	text-align: right;
	color: #dcdcdc;
}
.MiddleHolder {
	text-align: center;
	color: #dcdcdc;
}
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.new-line {
	white-space: pre-line;
}
.menuContainer {
	padding-bottom: 1rem;
}